import { Fragment, useContext, useEffect, useState } from 'react';
import { InsuranceT } from '@csi-monorepo/core/domain/insurance';
import { datadogRum } from '@datadog/browser-rum';
import getUfValueService from '@services/UF/getUfValue';
import HeaderProvider from '@src/components/Header/ContextHeader';
import { InsuranceContext } from '@src/contexts/insuranceContext';
import landingBreadcrumbs from '@src/data/landingbreadcrums.json';
import schemaFAQ from '@src/data/schemaFAQ.json';
import schemaOrganization from '@src/data/schemaOrganization.json';
import useScroll from '@src/hooks/screen/useScroll';
import Layout from '@src/layouts/layout';
import { getInsurance } from '@src/services/Insurance/getInsuranceService';
import getNextWorkingDayService from '@src/services/WorkingDays/getNextWorkingDayService';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import LandingPlansFeatures from '@src/templates/LandingPlansFeatures';
import { trackViewPLandingPageEvent } from '@src/utils/dataLayer/landing';
import usePaymentStore from '@stores/usePaymentStore';
import LandingHero from '@templates/LandingHero';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

const LandingPlans = dynamic(() => import('@src/templates/LandingPlans'));
const LandingDocuments = dynamic(() => import('@templates/LandingDocuments'));
const LandingFAQs = dynamic(() => import('@templates/LandingFAQs'));
const LandingComparator = dynamic(() => import('@templates/LandingComparator'));
const LandingAssists = dynamic(() => import('@templates/LandingAssists/LandingAssists'));
const LandingMidBanner = dynamic(() => import('@components/MidBanner'));

type LandingPageProps = {
  uf: number;
  dateUf: string;
  nextWorkingDay: string;
  insurance: InsuranceT;
};

const LandingPage: NextPage<LandingPageProps> = ({ uf, dateUf, nextWorkingDay, insurance }) => {
  const { isLoading } = useContext(InsuranceContext);
  const { setUFValue, setUFDate } = usePaymentStore();
  const { resetStore: resetPlanStore } = usePlanStore();
  const { setNextWorkingDay } = usePaymentStore();
  const { setInsurance, insurance: insuranceStore } = useAppStore();
  const { sponsor } = useSponsorStore();
  const [executeScrollFeatures, scrollRefFeatures] = useScroll();
  const [executeScrollPlans, scrollRefPlans] = useScroll();
  const [isScrolled, setIsScrolled] = useState(false);

  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) return;
    trackViewPLandingPageEvent(router?.query?.sponsor as string);
  }, [router.isReady]);

  useEffect(() => {
    setUFValue(uf);
    setUFDate(dateUf);
    setNextWorkingDay(nextWorkingDay);
  }, []);

  useEffect(() => {
    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (
      (isLoading === false && !insuranceStore?.insurance) ||
      (isLoading === false &&
        insuranceStore?.insurance &&
        !sponsor?.id &&
        (insuranceStore?.plans?.map((plan) => plan?.id)?.join(',') || '') !==
          (insurance?.plans?.map((plan) => plan?.id)?.join(',') || ''))
    ) {
      resetPlanStore();
      setInsurance(insurance);
    }
  }, [isLoading]);

  return (
    <Fragment>
      <Head>
        <script
          async
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaOrganization),
          }}
        />
        <script
          async
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaFAQ) }}
        />
        <script
          async
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(landingBreadcrumbs) }}
        />
      </Head>
      <HeaderProvider>
        <Layout isLanding showBanner scrollable={executeScrollPlans}>
          <LandingHero scrollable={executeScrollFeatures} />
          <LandingPlansFeatures
            scrollRef={scrollRefFeatures}
            setIsScrolled={setIsScrolled}
            isScrolled={isScrolled}
          />
          <LandingPlans scrollRef={scrollRefPlans} />
          <LandingAssists />
          <LandingMidBanner />
          <LandingComparator />
          <LandingDocuments />
          <LandingFAQs />
        </Layout>
      </HeaderProvider>
    </Fragment>
  );
};

export const getStaticProps = async () => {
  try {
    const { value: uf, date: dateUf } = await getUfValueService();
    const { nextWorkingDay } = await getNextWorkingDayService();
    const insurance: InsuranceT = await getInsurance();

    return {
      revalidate: process.env.STAGE === 'prod' ? 300 : 60,
      props: {
        uf: uf ?? null,
        dateUf: dateUf ?? null,
        nextWorkingDay: nextWorkingDay ?? null,
        insurance,
      },
    };
  } catch (error) {
    console.error('Error getStaticProps', { error });
  }
};

export default LandingPage;
