import { useEffect, useState } from 'react';

type MediaQueryStateT = {
  windowWidth: number;
  isDesiredWidth: boolean;
};

const useMediaQuery = (minWidth: number) => {
  const [state, setState] = useState<MediaQueryStateT>({
    windowWidth: 0,
    isDesiredWidth: false,
  });

  useEffect(() => {
    setState({
      windowWidth: window?.innerWidth ?? 0,
      isDesiredWidth: window?.innerWidth
        ? window?.innerWidth < minWidth
        : false,
    });
  }, []);

  useEffect(() => {
    if (window) {
      const resizeHandler = (): void => {
        const currentWindowWidth: number = window.innerWidth;
        const isDesiredWidth: boolean = currentWindowWidth < minWidth;
        setState({ windowWidth: currentWindowWidth, isDesiredWidth });
      };

      window.addEventListener('resize', resizeHandler);

      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, [state.windowWidth]);

  return state.isDesiredWidth;
};

export default useMediaQuery;
