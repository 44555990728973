import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { HeaderContext } from '@src/components/Header/ContextHeader';
import { LANDING } from '@src/constants/urls';
import { useRouter } from 'next/router';

import useDevice from './useDevice';

export const useHeader = (navLinkId: number) => {
  const { selected, handleSetSelected } = useContext(HeaderContext);
  const { mediaMedium } = useDevice();
  const { pathname } = useRouter();
  const { ref, inView } = useInView({
    threshold: mediaMedium ? [0.75, 1] : [0.25, 0.5],
  });

  useEffect(() => {
    if (pathname != LANDING) return;

    if (inView && navLinkId != 3) {
      handleSetSelected(navLinkId);
    }
  }, [inView, selected, navLinkId]);
  return ref;
};
