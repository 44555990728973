import { ChipHeroPropsT } from './ChipHero';

export const chips: ChipHeroPropsT[] = [
  {
    text: '<span>Hecho para ti</span> y tu familia',
    image: '/images/landing/hero_chip_1.png',
    position: {
      desktop: {
        positionY: -30,
        positionX: 220,
      },
      mobile: {
        positionY: -24,
        positionX: 220,
      },
    },
  },
  {
    text: '<span>Se activa</span> al día hábil siguiente',
    image: '/images/landing/hero_chip_2.png',
    position: {
      desktop: {
        positionY: 80,
        positionX: -40,
      },
      mobile: {
        positionY: 40,
        positionX: -24,
      },
    },
  },
  {
    text: '<span>Tú eliges</span> donde atenderte',
    image: '/images/landing/hero_chip_3.png',
    position: {
      desktop: {
        positionY: 120,
        positionX: 340,
      },
      mobile: {
        positionY: 130,
        positionX: 300,
      },
    },
  },
];

export default chips;
