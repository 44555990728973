import { Currency } from '@src/types/currency';

export const numberFormat = (value: number): string => {
  const float = value.toString().split('.');
  const formatedInteger = float[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return float[1] ? formatedInteger.concat(`,${float[1]}`) : formatedInteger;
};

export const currencyFormat = (value: number, currency: Currency, round?: boolean): string => {
  if (round) {
    return currency === 'CLP'
      ? `$${numberFormat(Math.round(value))}`
      : `UF ${numberFormat(parseFloat(value?.toFixed(4)))}`;
  }
  return currency === 'CLP'
    ? `$${numberFormat(parseFloat(value?.toFixed(2)))}`
    : `UF ${numberFormat(value)}`;
};

export const rutFormat = (_rut: string): string => {
  const rut = _rut.replace(/^0+|[^0-9kK]+/g, '').toLowerCase();
  let result = `${rut.slice(-4, -1)}${_rut.length > 1 ? '-' : ''}${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }
  return result;
};

export const documentNumberFormat = (document: string): { value: string; type: 'old' | 'new' } => {
  if (document.trim().length > 0 && document[0].match(/(a|A)/g)) {
    return {
      value: document.toUpperCase(),
      type: 'old',
    };
  }

  const formatDocument = document.replace(/\D/g, '');
  let result = `${formatDocument.slice(-3)}`;
  for (let i = 3; i < formatDocument.length; i += 3) {
    result = `${formatDocument.slice(-3 - i, -i)}.${result}`;
  }

  return {
    value: result,
    type: 'new',
  };
};

export const safeDualCurrency = (
  uf: number,
  ufValue?: number,
  roundUf = false,
  roundCLP = true
): string => {
  if (ufValue)
    return `${currencyFormat(uf * ufValue, 'CLP', roundCLP)} (${currencyFormat(
      uf,
      'UF',
      roundUf
    )})`;
  return currencyFormat(uf, 'UF', roundUf);
};

export const convertSafeUFToPesos = (
  uf: number,
  pesosEquivalent: number,
  addCurrency = true
): string | number => {
  if (uf && uf > 0 && pesosEquivalent && pesosEquivalent > 0) {
    return addCurrency
      ? currencyFormat(Math.round(uf * pesosEquivalent), 'CLP', true)
      : Math.round(uf * pesosEquivalent);
  }

  return 0;
};
