import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import componentsId from '@constants/componentsId';
import QuantityMoreInformationModal from '@src/components/DependantQuote/QuantityMoreInformationModal';
import HeroLanding from '@src/components/HeroLanding/HeroLanding';
import ScrollableArrowDown from '@src/components/ScrollableArrowDown/ScrollableArrowDown';
import { QUOTATION_FULL } from '@src/constants/urls';
import useDevice from '@src/hooks/screen/useDevice';
import { useHeader } from '@src/hooks/screen/useHeader';
import { currencyFormat } from '@src/libs/format';
import useAppStore from '@src/stores/useAppStore';
import useFlowStore, { supportedFlowsEnum } from '@src/stores/useFlowStore';
import usePaymentStore from '@src/stores/usePaymentStore';
import { hiringBtnEvent } from '@src/utils/dataLayer/landing';
import Image from 'next/image';
import { useRouter } from 'next/router';

import styles from './LandingHero.module.scss';

type LandingHeroPropsT = {
  scrollable: () => void;
};

const LandingHero: React.FC<LandingHeroPropsT> = ({ scrollable }): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [minPrice, setMinPrince] = useState<number>();
  const { insurance } = useAppStore();
  const { ufValue } = usePaymentStore();
  const { isMobile } = useDevice();
  const { setFlowName } = useFlowStore();
  const heroRef = useHeader(componentsId.landingHeroId.index);

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleScrollClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    hiringBtnEvent();

    setFlowName(supportedFlowsEnum.traditional) as string;
    router.push(QUOTATION_FULL);
  };

  const getMinPrice = (): string => {
    if (!minPrice || !ufValue) return '';
    return currencyFormat(minPrice * (ufValue || 0), 'CLP', true);
  };

  useEffect(() => {
    if (insurance?.plans?.length > 0) setMinPrince(insurance?.plans[0].prices[0].price);
  }, [insurance?.plans]);

  return (
    <section className={styles.landing_hero_container} id={componentsId.landingHeroSection}>
      <div className={styles.landing_hero}>
        <div className={styles.landing_hero_section}>
          <div className={styles.landing_hero_logo}>
            <Image
              src="/images/logo/partner_salud_logo.png"
              height={30}
              width={170}
              alt=""
              loading="lazy"
            />
          </div>
          <h1 className={styles.landing_hero_title} ref={heroRef}>
            <Trans i18nKey="LANDING_HERO_TITLE" components={{ span: <span /> }} />
          </h1>
          <p className={styles.landing_hero_description}>
            <Trans
              values={{ minPrice: getMinPrice() }}
              i18nKey={
                minPrice ? 'LANDING_HERO_TITLE_DESCRIPTION_FULL' : 'LANDING_HERO_TITLE_DESCRIPTION'
              }
              components={{ span: <span /> }}
            />
          </p>
          <div className={styles.landing_hero_button}>
            <Button
              id={componentsId.landingHeroBtnPrecios}
              data-testid="landing-hero-continue-button"
              size="l"
              onClick={handleScrollClick}
            >
              {t('LANDING_HERO_TEXT_BUTTON_QUOTATION')}
            </Button>
          </div>
        </div>
        <HeroLanding />
      </div>
      {!isMobile && (
        <div className={styles.arrowdown_container}>
          <ScrollableArrowDown scrollable={scrollable} />
        </div>
      )}
      <QuantityMoreInformationModal isOpen={isModalOpen} onClose={handleOpenModal} />
    </section>
  );
};

export default LandingHero;
