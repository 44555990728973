import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import { Button } from '@bice_vida/ds-components-button';
import { FullModalContext } from '@src/components/Header/FullModalContext';
import ListItemCheckMark from '@src/components/ListItemCheckMark';
import { COVERAGE_TABS } from '@src/constants/planCoverages';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { clickViewDetailsWhatCoverSectionEvent } from '@src/utils/dataLayer/landing';
import classNames from 'classnames';
import Image from 'next/image';

import styles from './WhatCoverTab.module.scss';

type WhatCoverTabProps = {
  isActive: boolean;
};

const WhatCoverTab: FC<WhatCoverTabProps> = ({ isActive }) => {
  const { onOpenModalHandler, currentModalTab } = useContext(FullModalContext);
  const { userId } = useAppStore();
  const { isAided, executiveSale } = useAidedSellingStore();
  const { name } = usePlanStore();
  const { sponsor } = useSponsorStore();
  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES.WHAT_COVER',
  });

  const whatCoverClassnames = classNames(styles.WhatCover, {
    [styles.WhatCover__notActive]: !isActive,
  });

  const handleOpenCoverages = () => {
    onOpenModalHandler(currentModalTab, COVERAGE_TABS.coverages);
    clickViewDetailsWhatCoverSectionEvent({
      isAided: isAided || !!executiveSale,
      userId,
      planName: name,
      sponsor: sponsor?.code,
      preQuotation: sponsor?.preQuotation,
    });
  };

  return (
    <div className={whatCoverClassnames}>
      <div className={styles.WhatCover__card}>
        <Image
          src="/images/landing/features/check.svg"
          width={80}
          height={80}
          alt=""
          className={styles.WhatCover__card__image}
        />
        <Text className={styles.WhatCover__card__title}>
          <Trans>{t('CARD_1.TITLE')}</Trans>
        </Text>
        <Text className={styles.WhatCover__card__subtitle}>
          <Trans>{t('CARD_1.SUBTITLE_1')}</Trans>
        </Text>
        <ul>
          <Trans
            components={{
              li: <ListItemCheckMark textClassName={styles.WhatCover__card__list_item} />,
            }}
          >
            {t('CARD_1.DESC_1')}
          </Trans>
          <Trans
            components={{
              li: <ListItemCheckMark textClassName={styles.WhatCover__card__list_item} />,
            }}
          >
            {t('CARD_1.DESC_2')}
          </Trans>
          <Trans
            components={{
              li: <ListItemCheckMark textClassName={styles.WhatCover__card__list_item} />,
            }}
          >
            {t('CARD_1.DESC_3')}
          </Trans>
          <Trans
            components={{
              li: <ListItemCheckMark />,
            }}
          >
            {t('CARD_1.DESC_4')}
          </Trans>
        </ul>
        <Text className={styles.WhatCover__card__subtitle}>
          <Trans>{t('CARD_1.SUBTITLE_2')}</Trans>
        </Text>
        <ul>
          <Trans
            components={{
              li: <ListItemCheckMark textClassName={styles.WhatCover__card__list_item} />,
            }}
          >
            {t('CARD_1.DESC_4')}
          </Trans>
          <Trans
            components={{
              li: <ListItemCheckMark textClassName={styles.WhatCover__card__list_item} />,
            }}
          >
            {t('CARD_1.DESC_5')}
          </Trans>
          <Button
            variant="link"
            className={styles.WhatCover__card__link}
            onClick={handleOpenCoverages}
            rightIcon={<i className="material-icons">arrow_forward</i>}
          >
            {t('CARD_1.BUTTON')}
          </Button>
        </ul>
      </div>
      <div className={styles.WhatCover__card}>
        <Image
          src="/images/landing/features/close.svg"
          width={80}
          height={80}
          alt=""
          className={styles.WhatCover__card__image}
        />
        <Text className={styles.WhatCover__card__title}>
          <Trans>{t('CARD_2.TITLE')}</Trans>
        </Text>
        <ul>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_1')}
          </Trans>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_2')}
          </Trans>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_3')}
          </Trans>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_4')}
          </Trans>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_5')}
          </Trans>
          <Trans
            components={{
              li: (
                <ListItemCheckMark
                  listItemClassName={styles.WhatCover__card__crossIcon}
                  textClassName={styles.WhatCover__card__list_item}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_6')}
          </Trans>
        </ul>
      </div>
    </div>
  );
};
export default WhatCoverTab;
