import { PaymentInformationT, PaymentMethodsT, PaymentT } from '@src/types/payment';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type PaymentActions = {
  setUFValue: (ufToday: number) => void;
  setUFDate: (ufDate: string) => void;
  setPaymentMethod: (paymentMethod: PaymentMethodsT) => void;
  setDaysToEndmonth: (days: number) => void;
  setNextWorkingDay: (date: string) => void;
  setProportionalPrice: (proportionalUf: number) => void;
  setProportionalTotalPrice: (proportionalUf: number) => void;
  setNextPayment: (date: string) => void;
  setDiscountCode: (codeName: string) => void;
  setDiscountRate: (rate: number) => void;
  setProportionalpriceWithDiscount: (uf: number) => void;
  setDiscountId: (id: string) => void;
  setAmountDiscounted: (discounted: number) => void;
  setIsDiscounted: (isDiscounted: boolean) => void;
  setTokuPaymentInformation: (tokuInformation: PaymentInformationT['tokuInformation']) => void;
  resetStore: () => void;
};

type PaymentState = PaymentT & PaymentActions;

const initialState: PaymentInformationT = {
  method: undefined,
  ufValue: 0,
  ufDate: '',
  price: 0,
  nextWorkingDay: '',
  daysToEndMonthFromNextWorkingDay: 0,
  proportionalPrice: 0,
  proportionalTotalPrice: 0,
  nextPayment: '',
  discountCode: '',
  discountCodeRate: 0,
  proportionalPriceWithDiscount: 0,
  discountId: '',
  amountDiscounted: 0,
  isDiscounted: false,
  tokuInformation: {
    customerId: undefined,
    invoiceId: undefined,
    subscriptionId: undefined,
  },
};

const usePaymentStore = create<PaymentState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setUFValue: (ufToday) => set({ ufValue: ufToday }),
        setUFDate: (ufDate) => set({ ufDate: ufDate }),
        setPaymentMethod: (paymentMethod) => set({ method: paymentMethod }),
        setDaysToEndmonth: (days) => set({ daysToEndMonthFromNextWorkingDay: days }),
        setNextWorkingDay: (date) => set({ nextWorkingDay: date }),
        setProportionalPrice: (proportionalUf) =>
          set({
            proportionalPrice: proportionalUf,
          }),
        setProportionalTotalPrice: (proportionalUf) =>
          set({ proportionalTotalPrice: proportionalUf }),
        setNextPayment: (date) => set({ nextPayment: date }),
        setDiscountCode: (codeName) => set({ discountCode: codeName }),
        setDiscountRate: (rate) => set({ discountCodeRate: rate }),
        setProportionalpriceWithDiscount: (Uf) =>
          set({
            proportionalPriceWithDiscount: Uf,
          }),
        setDiscountId: (id) => set({ discountId: id }),
        setAmountDiscounted: (discounted) => set({ amountDiscounted: discounted }),
        setIsDiscounted: (isDiscounted) => set({ isDiscounted: isDiscounted }),
        setTokuPaymentInformation: (tokuInformation) => set({ tokuInformation }),
        resetStore: () => set({ ...initialState }),
      }),
      {
        name: 'csi-payment-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default usePaymentStore;
