import React, { ReactNode, useEffect, useRef, useState } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';
import { Icon, IconAlt } from '@BICE_Vida/components';
import { Text } from '@BICE_Vida/components';
import { TextT } from '@BICE_Vida/components/dist/components/Text/Text';
import classNames from 'classnames';

import './CustomAccordion.module.scss';

const HEADER_HEIGHT = 130;

type AccordionGroupT = {
  /**
   * Declares custom class for wrapper
   */
  className?: string;
  children: ReactNode;
};

export function AccordionGroup({ className, children }: AccordionGroupT) {
  const _className = classNames('bv_accordion__group', className);

  return <div className={_className}>{children}</div>;
}

export type AccordionT = {
  /**
   * Declares id attribute.
   * */
  id?: string;
  /**
   * Declares click function.
   * */
  onClick?: (isOpen?: boolean) => void;
  /**
   * Declares disabled state.
   * */
  disabled?: boolean;
  /**
   * Declares header text.
   * */
  headerText: string;
  headerClassName?: string;
  /**
   * Declares bordered format.
   */
  noBorder?: boolean;

  /**
   * Declares shadow style
   */
  noShadow?: boolean;
  /**
   * Declares classes to customize the accordion element.
   * */
  className?: string;
  /**
   * Declares an icon for the accordion header.
   */
  icon?: JSX.Element;
  /**
   * Declares if the accordion is open.
   */
  isOpen?: boolean;

  /**
   * Declares classes to customize the accordion children
   */
  contentClassName?: string;

  /**
   * Declares accordion animation speed in ms
   */
  animationDuration?: number;
  headerChildren?: ReactNode;
  /**
   * scroll for the accordion
   */
  scrollToTop?: boolean;
  /**
   * Declares Text props except children
   */
  headerProps?: Omit<TextT, 'children'>;
  /**
   * Declares React Animate Height component  props except
   * children, animationDuration and contentClassName
   */
  animateProps?: Omit<AnimateHeightProps, 'children' | 'animationDuration' | 'contentClassName'>;
};

export type AccordionPropT = AccordionT & { children: ReactNode };

/**
 * Accordion Component
 *
 * Recomendación: utilizar `<AccordionGroup />` para contener los `<Accordion/>`.
 */

function CustomAccordion({
  id,
  onClick,
  disabled,
  headerText,
  headerProps,
  headerClassName,
  noBorder,
  noShadow,
  className,
  contentClassName,
  animationDuration,
  animateProps,
  icon,
  isOpen,
  scrollToTop = false,
  headerChildren,
  children,
}: AccordionPropT) {
  const [showBody, setShowBody] = useState(isOpen);
  const ref = useRef<HTMLDivElement>(null);

  const classNameAccordion = classNames('bv_accordion', className, {
    'bv_accordion-disabled': disabled,
  });

  const classNameHeader = classNames(headerClassName, 'bv_accordion__header', {
    'bv_accordion__header-bordered': !noBorder,
    'bv_accordion__header-shadow': !noShadow,
  });

  const classNameAccordionBody = classNames('bv_accordion__body-no-animation', contentClassName);

  const onClickHeaderHandler = () => {
    setShowBody(!showBody);
    onClick && onClick(!showBody);

    if (scrollToTop && !showBody) {
      setTimeout(() => {
        window.scrollTo({
          top: (ref.current?.offsetTop ?? 0) - HEADER_HEIGHT,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setShowBody(isOpen);
    }
  }, [isOpen]);

  return (
    <div className={classNameAccordion} id={id} ref={ref}>
      <div className={classNameHeader} role="button" tabIndex={0} onClick={onClickHeaderHandler}>
        <Text fontWeight={500} fontTypo="title-small" {...headerProps}>
          {headerText}
        </Text>
        {headerChildren}
        <span className="bv_accordion__header__icon">
          {icon || (
            <IconAlt text={showBody ? 'Cerrar' : 'Abrir'} position={showBody ? 'bottom' : 'top'}>
              <Icon
                name={showBody ? 'expand_less' : 'expand_more'}
                disabled={disabled}
                withBg
                size="medium"
              />
            </IconAlt>
          )}
        </span>
      </div>

      {!scrollToTop ? (
        <AnimateHeight
          {...animateProps}
          duration={animationDuration}
          height={showBody ? 'auto' : 0}
          contentClassName={classNameAccordionBody}
        >
          {children}
        </AnimateHeight>
      ) : (
        <>{showBody && children}</>
      )}
    </div>
  );
}

CustomAccordion.defaultProps = {
  disabled: false,
  isOpen: false,
  noBorder: false,
  noShadow: false,
  animationDuration: 300,
  animateProps: {
    animateOpacity: true,
  },
};

export default CustomAccordion;
