import { RefObject, useRef } from 'react';

const useScroll = (): [() => void, RefObject<HTMLDivElement>] => {
  const htmlElementRef = useRef<HTMLDivElement | null>(null);
  const executeScroll = (): void => {
    if (htmlElementRef.current) {
      const element = htmlElementRef.current;
      const headerOffset = 110;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return [executeScroll, htmlElementRef];
};

export default useScroll;
