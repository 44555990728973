import React, { useEffect, useState } from 'react';
import useDevice from '@src/hooks/screen/useDevice';
import Image from 'next/image';
import useNextBlurhash from 'use-next-blurhash';

import ChipHero from './ChipHero';
import chips from './chips';

import styles from './HeroLanding.module.scss';

const HeroLanding = () => {
  const { isMobile } = useDevice();
  const [auxImgHeight, setAuxImgHeight] = useState<number>(378);
  const [auxImgWidth, setAuxImgWidth] = useState<number>(461);
  const [blurDataUrl] = useNextBlurhash('LSM%+iRk_NX7?bWEM{xt^*tQM|V@');

  useEffect(() => {
    setAuxImgHeight(isMobile ? 215 : 378);
    setAuxImgWidth(isMobile ? 262 : 461);
  }, [isMobile]);

  return (
    <div className={styles.container}>
      <div className={styles.outside_circle}>
        <div className={styles.circle} />
        {chips.map(({ text, image, position }, index) => (
          <ChipHero key={index} text={text} image={image} position={position} />
        ))}
      </div>
      <div className={styles.images}>
        <Image
          src="/images/landing/hero-new-collage.png"
          height={auxImgHeight}
          width={auxImgWidth}
          alt=""
          placeholder="blur"
          blurDataURL={blurDataUrl}
          priority
        />
      </div>
    </div>
  );
};

export default HeroLanding;
