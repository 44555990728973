import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import { Modal } from '@BICE_Vida/tsp-components';
import Image from 'next/image';

import styles from './ModalDependantsQuotation.module.scss';

type DependantModalPropT = {
  isVisible: boolean;
  onClose: () => void;
  className?: string;
};

const ModalDependantsQuotation: React.FC<DependantModalPropT> = ({ onClose, isVisible }) => {
  const { t } = useTranslation();

  return (
    <Modal classNameModal={styles.modal} isHalf={true} isOpen={isVisible} onClose={onClose}>
      <div className={styles.container_modal}>
        <Image src="/images/icon/familiares_dependientes.png" width={80} height={80} alt="" />
        <p className={styles.title}>
          <Trans i18nKey="DEPENDANTS_QUOTATION_MODAL_TITLE" />
        </p>

        <div className={styles.container_body}>
          <p className={styles.description}>
            <Trans components={{ span: <span /> }} i18nKey="DEPENDANTS_QUOTATION_MODAL_DESC" />
          </p>

          <ul className={styles.item_container}>
            {[0, 1, 2, 3, 4].map((item) => (
              <li className={styles.description_item} key={`item_modal_${item}`}>
                <Trans
                  components={{ span: <span /> }}
                  i18nKey={`DEPENDANTS_QUOTATION_MODAL_ITEM_${item}`}
                />
              </li>
            ))}
          </ul>

          <p className={styles.description}>
            <Trans components={{ span: <span /> }} i18nKey="DEPENDANTS_QUOTATION_MODAL_SUB_DESC" />
          </p>

          <Button size="l" className={styles.button} onClick={onClose}>
            {t('DEPENDANTS_QUOTATION_BUTTON')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDependantsQuotation;
