import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './ListItemCheckMark.module.scss';

type ListItemCheckMarkPropT = {
  listItemClassName?: string;
  textClassName?: string;
};

const ListItemCheckMark = ({
  listItemClassName,
  textClassName,
  children,
}: PropsWithChildren<ListItemCheckMarkPropT>): JSX.Element => {
  const listItemClassNames = classNames(listItemClassName, styles.list_item);
  const textClassNames = classNames(textClassName, styles.list_item_text);

  return (
    <li className={listItemClassNames}>
      <p className={textClassNames}>{children}</p>
    </li>
  );
};

export default ListItemCheckMark;
