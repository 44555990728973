import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import ListItemCheckMark from '@src/components/ListItemCheckMark';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { clickViewDetailsFeaturesSectionEvent } from '@src/utils/dataLayer/landing';
import classNames from 'classnames';
import Image from 'next/image';

import styles from './FeaturesTab.module.scss';

type FeaturesTabProps = {
  openModal: () => void;
  isActive: boolean;
};

const FeatureTab: FC<FeaturesTabProps> = ({ openModal, isActive }) => {
  const { userId } = useAppStore();
  const { isAided, executiveSale } = useAidedSellingStore();
  const { name } = usePlanStore();
  const { sponsor } = useSponsorStore();
  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES.FEATURES',
  });

  const featuresClassnames = classNames(styles.Features, {
    [styles.Features__notActive]: !isActive,
  });

  return (
    <div className={featuresClassnames}>
      <div className={styles.Features__card}>
        <div className={styles.Features__card__image}>
          <Image src="/images/icon/notes.png" height={80} width={80} alt="" />
        </div>
        <Text className={styles.Features__card__title}>
          <Trans>{t('CARD_1.TITLE')}</Trans>
        </Text>
        <ul>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_1.DESC_1')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_1.DESC_2')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_1.DESC_3')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_1.DESC_4')}</Trans>
        </ul>
      </div>
      <div className={styles.Features__card}>
        <div className={styles.Features__card__image}>
          <Image src="/images/icon/folder.png" height={80} width={80} alt="" />
        </div>
        <Text className={styles.Features__card__title}>
          <Trans>{t('CARD_2.TITLE')}</Trans>
        </Text>
        <ul>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_2.DESC_1')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_2.DESC_2')}</Trans>
        </ul>
        <Text className={styles.Features__card__text}>
          <Trans
            components={{
              a: (
                <a
                  onClick={() => {
                    openModal();
                    clickViewDetailsFeaturesSectionEvent({
                      isAided: isAided || !!executiveSale,
                      userId,
                      planName: name,
                      sponsor: sponsor?.code,
                      preQuotation: sponsor?.preQuotation,
                    });
                  }}
                  className={styles.Features__card__link}
                />
              ),
            }}
          >
            {t('CARD_2.DESC_3')}
          </Trans>
        </Text>
      </div>
      <div className={styles.Features__card}>
        <div className={styles.Features__card__image}>
          <Image src="/images/icon/coin.png" height={80} width={80} alt="" />
        </div>
        <Text className={styles.Features__card__title}>
          <Trans>{t('CARD_3.TITLE')}</Trans>
        </Text>
        <ul>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_3.DESC_1')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_3.DESC_2')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_3.DESC_3')}</Trans>
          <Trans components={{ li: <ListItemCheckMark /> }}>{t('CARD_3.DESC_4')}</Trans>
        </ul>
      </div>
    </div>
  );
};

export default FeatureTab;
