import { RefObject, useEffect, useState } from 'react';

export const useOnScreen = (ref: RefObject<Element>) => {
  const [isOnScreen, setOnScreen] = useState<boolean | undefined>();
  let observer: IntersectionObserver;

  useEffect(() => {
    observer = new IntersectionObserver(([entry]) => setOnScreen(entry.isIntersecting), {
      threshold: [0.25, 0.5, 0.75],
    });
  }, []);

  useEffect(() => {
    if (ref?.current) observer.observe(ref?.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isOnScreen;
};
