import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import { Button } from '@bice_vida/ds-components-button';
import { currencyFormat } from '@src/libs/format';
import useAppStore from '@src/stores/useAppStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import classNames from 'classnames';
import Image from 'next/image';

import styles from './HowWorksAccordion.module.scss';

const SAVED_WITH_FONASA = 30000;
const TOTAL_WITHOUT_INSURANCE = 20000;

type HowWorksAccordionProps = {
  openBmiModal: () => void;
  isActive: boolean;
};

const HowWorksAccordion: FC<HowWorksAccordionProps> = ({ openBmiModal, isActive }) => {
  const { sponsor } = useSponsorStore();
  const { insurance } = useAppStore();
  const haveBMI = insurance?.plans?.some(({ bmi }) => !!bmi);

  const plansWithoutBMI = insurance?.plans?.filter(({ bmi }) => !bmi);

  const mostExpensivePlan = plansWithoutBMI?.reduce(
    (prev, current) => (prev?.prices[0].price > current?.prices[0].price ? prev : current),
    plansWithoutBMI[0]
  );

  const refundPercentage = mostExpensivePlan?.coverages
    ?.find(({ name }) => name === 'Gastos ambulatorios')
    ?.subCoverages?.find(({ name }) => name === 'Consultas médicas')?.withBonus;

  const refoundAmount =
    (Number(refundPercentage?.replace('%', '')) / 100) * TOTAL_WITHOUT_INSURANCE ?? 0;

  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES.HOW_WORKS',
  });

  const howWorksClassnames = classNames(styles.HowWorks, {
    [styles.HowWorks__notActive]: !isActive,
  });

  return (
    <div className={howWorksClassnames}>
      <div className={styles.HowWorks__image}>
        <Image src="/images/bicente/bicente_pensando.png" width={48} height={48} alt="" />
      </div>
      <Text className={styles.HowWorks__title}>
        <Trans>{t('TITLE')}</Trans>
      </Text>
      <Text className={styles.HowWorks__paragraph}>
        <Trans
          components={{ span: <span /> }}
          values={{
            refound: refoundAmount,
            planName: mostExpensivePlan?.name,
            refoundAmount: currencyFormat(refoundAmount, 'CLP'),
            save: currencyFormat(TOTAL_WITHOUT_INSURANCE - refoundAmount, 'CLP'),
            pay: currencyFormat(SAVED_WITH_FONASA + refoundAmount, 'CLP'),
          }}
        >
          {t('PARAGRAPH')}
        </Trans>
      </Text>
      <div className={styles.HowWorks__cost}>
        <div className={styles.HowWorks__cost__row}>
          <Text className={styles.HowWorks__cost__row__text}>
            <Trans>{t('MEDICAL_CONSULTATION')}</Trans>
          </Text>
          <Text className={styles.HowWorks__cost__row__price}>
            <Trans>{t('MEDICAL_CONSULTATION_COST')}</Trans>
          </Text>
        </div>
        <div className={styles.HowWorks__cost__row}>
          <Text className={styles.HowWorks__cost__row__text_blue}>
            <Trans>{t('FONASA')}</Trans>
          </Text>
          <Text className={styles.HowWorks__cost__row__discount}>
            <Trans>{t('FONASA_DISCOUNT')}</Trans>
          </Text>
        </div>
        <div className={styles.HowWorks__cost__divider}></div>
        <div className={styles.HowWorks__cost__row}>
          <Text className={styles.HowWorks__cost__row__text}>
            <Trans>{t('TOTAL_WITHOUT_INSURANCE')}</Trans>
          </Text>
          <Text className={styles.HowWorks__cost__row__price__gray}>
            <Trans>{t('TOTAL_WITHOUT_INSURANCE_PRICE')}</Trans>
          </Text>
        </div>
        <div className={styles.HowWorks__cost__row}>
          <Text className={styles.HowWorks__cost__row__text_blue}>
            <Trans values={{ refundPercentage }}>{t('CSI')}</Trans>
          </Text>
          <Text className={styles.HowWorks__cost__row__discount}>
            <Trans>{t('CSI_DISCOUNT')}</Trans>
          </Text>
        </div>
        <div className={styles.HowWorks__cost__divider}></div>
        <div className={styles.HowWorks__cost__row}>
          <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
            <Image src="/images/landing/features/pay_icon.svg" width={24} height={24} alt="" />
            <Text className={styles.HowWorks__cost__row__text}>
              <Trans>{t('PAY')}</Trans>
            </Text>
          </div>
          <Text className={styles.HowWorks__cost__row__price__bold}>
            <Trans>{t('PAY_COST')}</Trans>
          </Text>
        </div>
        <div className={styles.HowWorks__cost__row}>
          <div style={{ display: 'flex', gap: 4 }}>
            <Image src="/images/landing/features/save_icon.svg" width={24} height={24} alt="" />
            <Text className={styles.HowWorks__cost__row__text}>
              <Trans>{t('SAVED')}</Trans>
            </Text>
          </div>
          <Text className={styles.HowWorks__cost__row__price__green}>
            <Trans>{t('SAVED_PRICE')}</Trans>
          </Text>
        </div>
      </div>
      {(sponsor?.bmi || haveBMI) && (
        <Text>
          <Button className={styles.HowWorks__bmi} onClick={() => openBmiModal()} variant="link">
            {t('BMI')}
          </Button>
        </Text>
      )}
      <div className={styles.HowWorks__deductible}>
        <Image src="/images/landing/features/pencil.svg" height={48} width={48} alt="" />
        <div className={styles.HowWorks__deductible__texts}>
          <Text className={styles.HowWorks__deductible__texts__title}>
            <Trans>{t('DEDUCTIBLE_TITLE')}</Trans>
          </Text>
          <Text className={styles.HowWorks__deductible__texts__paragraph}>
            <Trans components={{ span: <span /> }}>{t('DEDUCTIBLE_PARAGRAPH')}</Trans>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HowWorksAccordion;
