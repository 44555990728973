import React from 'react';
import { Icon } from '@BICE_Vida/components';

import styles from './ScrollableArrowDown.module.scss';

type ScrollableArrowDownPropsT = {
  scrollable: () => void;
  size?: 'small' | 'large' | 'xsmall' | 'medium' | 'xlarge';
};

const ScrollableArrowDown: React.FC<ScrollableArrowDownPropsT> = ({
  scrollable,
  size = 'xlarge',
}) => {
  return (
    <div className={styles.icon_container} onClick={() => scrollable()}>
      <Icon name={'arrow_downward'} size={size} className={styles.arrowdown_ward} />
    </div>
  );
};

export default ScrollableArrowDown;
