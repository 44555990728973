import { FC, RefObject, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, Text } from '@BICE_Vida/components';
import CustomAccordion from '@src/components/CustomAccordion';
import useMediaQuery from '@src/hooks/screen/useMediaQuery';
import { useOnScreen } from '@src/hooks/screen/useOnScreen';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { LandingPlansFeaturesEnum } from '@src/types/landing';
import { changeTabFeaturesSectionEvent } from '@src/utils/dataLayer/landing';

import ModalDependantsQuotation from '../QuotationTemplate/ModalDependants/ModalDependantsQuotation';

import AdvantagesAccordion from './accordions/AdvantagesAccordion';
import FeaturesAccordion from './accordions/FeaturesAccordion';
import HowWorksAccordion from './accordions/HowWorksAccordion';
import WhatCoverAccordion from './accordions/WhatCoverAccordion';
import AdvantagesTab from './tabs/AdvantagesTab';
import FeatureTab from './tabs/FeaturesTab';
import HowWorksTab from './tabs/HowWorksTab';
import WhatCoverTab from './tabs/WhatCoverTab';
import BmiModal from './BmiModal';

import styles from './LandingPlansFeatures.module.scss';

type LandingPlansFeaturesProps = {
  setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
  isScrolled: boolean;
  scrollRef: RefObject<HTMLDivElement>;
};

const LandingPlansFeatures: FC<LandingPlansFeaturesProps> = ({
  scrollRef,
  setIsScrolled,
  isScrolled,
}) => {
  const { userId } = useAppStore();
  const { isAided, executiveSale } = useAidedSellingStore();
  const { name } = usePlanStore();
  const [isOpenDependantModal, setIsOpenDependantModal] = useState(false);
  const [isOpenBmiModal, setIsOpenBmiModal] = useState(false);
  const [tab, setTab] = useState(LandingPlansFeaturesEnum.ComoFunciona);
  const isMobile = useMediaQuery(992);
  const isInScreen = useOnScreen(scrollRef);
  const { sponsor } = useSponsorStore();

  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES',
  });

  useEffect(() => {
    if (isInScreen && !isScrolled) setIsScrolled(true);
  }, [isInScreen]);

  return (
    <>
      <div className={styles.plansFeatures} ref={scrollRef}>
        <Text tag="h2" className={styles.plansFeatures__title}>
          <Trans components={{ span: <span />, br: <br /> }}>{t('TITLE')}</Trans>
        </Text>
        {isMobile ? (
          <div className={styles.plansFeatures__accordion}>
            {Object.values(LandingPlansFeaturesEnum).map((value, index) => {
              return (
                <CustomAccordion
                  headerText={value}
                  key={index}
                  scrollToTop
                  isOpen={tab === value}
                  onClick={() => {
                    setTab(value);
                    changeTabFeaturesSectionEvent({
                      isAided: isAided || !!executiveSale,
                      tab: value,
                      userId,
                      planName: name,
                      sponsor: sponsor?.code,
                      preQuotation: sponsor?.preQuotation,
                    });
                  }}
                >
                  <FeaturesAccordion
                    openModal={() => setIsOpenDependantModal((prevState) => !prevState)}
                    isActive={tab === LandingPlansFeaturesEnum.caracteristicas}
                  />
                  <WhatCoverAccordion isActive={tab === LandingPlansFeaturesEnum.queCubre} />
                  <HowWorksAccordion
                    openBmiModal={() => setIsOpenBmiModal((prevState) => !prevState)}
                    isActive={tab === LandingPlansFeaturesEnum.ComoFunciona}
                  />
                  <AdvantagesAccordion isActive={tab === LandingPlansFeaturesEnum.beneficios} />
                </CustomAccordion>
              );
            })}
          </div>
        ) : (
          <div className={styles.plansFeatures__tabs}>
            <Tab
              fullWidth
              activeTab={tab}
              items={Object.values(LandingPlansFeaturesEnum).map((value) => ({
                text: value,
                id: value,
              }))}
              onChange={(id) => {
                setTab(id as LandingPlansFeaturesEnum);
                changeTabFeaturesSectionEvent({
                  isAided: isAided || !!executiveSale,
                  tab: id as LandingPlansFeaturesEnum,
                  userId,
                  planName: name,
                  sponsor: sponsor?.code,
                  preQuotation: sponsor?.preQuotation,
                });
              }}
              className={styles.plansFeatures__tabs__tab}
            />
            <div className={styles.plansFeatures__tabs__content}>
              <FeatureTab
                openModal={() => setIsOpenDependantModal((prevState) => !prevState)}
                isActive={tab === LandingPlansFeaturesEnum.caracteristicas}
              />
              <WhatCoverTab isActive={tab === LandingPlansFeaturesEnum.queCubre} />
              <HowWorksTab
                openBmiModal={() => setIsOpenBmiModal((prevState) => !prevState)}
                isActive={tab === LandingPlansFeaturesEnum.ComoFunciona}
              />
              <AdvantagesTab isActive={tab === LandingPlansFeaturesEnum.beneficios} />
            </div>
          </div>
        )}
      </div>
      <ModalDependantsQuotation
        isVisible={isOpenDependantModal}
        onClose={() => setIsOpenDependantModal(false)}
      />
      <BmiModal isVisible={isOpenBmiModal} onClose={() => setIsOpenBmiModal(false)} />
    </>
  );
};

export default LandingPlansFeatures;
