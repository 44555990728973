import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import { Button } from '@bice_vida/ds-components-button';
import { useDevice } from '@BICE_Vida/tsp-components/dist/hooks';
import BaseModal from '@src/components/BaseModal';
import { currencyFormat } from '@src/libs/format';
import useAppStore from '@src/stores/useAppStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import Image from 'next/image';

import styles from './BmiModal.module.scss';

const TOTAL_WITHOUT_INSURANCE = 30000;
const SAVED_WITH_FONASA = 20000;
const TOTAL_WITH_BMI = 25000;

type BmiModalModalProp = {
  isVisible: boolean;
  onClose: () => void;
  className?: string;
};

const BmiModal: React.FC<BmiModalModalProp> = ({ isVisible, onClose }) => {
  const { sponsor } = useSponsorStore();
  const { maxMediaSmall: isMobile } = useDevice();
  const { insurance } = useAppStore();
  const plansWithBMI = sponsor ? insurance?.plans : insurance?.plans?.filter(({ bmi }) => !!bmi);
  const mostExpensivePlan = plansWithBMI?.[(plansWithBMI?.length || 1) - 1];

  const refundPercentage = mostExpensivePlan?.coverages
    ?.find(({ name }) => name === 'Gastos ambulatorios')
    ?.subCoverages?.find(({ name }) => name === 'Consultas médicas')?.withBonus;

  const refoundAmount = (Number(refundPercentage?.replace('%', '')) / 100) * TOTAL_WITH_BMI ?? 0;

  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES.HOW_WORKS',
  });
  return (
    <BaseModal
      isVisible={isVisible}
      onClose={onClose}
      closeIcon="default"
      modalWidth={isMobile ? 332 : 608}
      className={styles.bmi_modal}
    >
      <div className={styles.bmi_modal__container}>
        <div className={styles.bmi_modal__container__scrollable}>
          <div className={styles.bmi_modal__container__scrollable__header}>
            <Image
              src="/images/bicente/bicente_pensando.png"
              width={80}
              height={80}
              alt=""
              layout="fixed"
              className={styles.bmi_modal__container__scrollable__header__logo}
            />
            <p className={styles.bmi_modal__container__scrollable__header__title}>
              {t('MODAL_TITLE')}
            </p>
          </div>

          <div className={styles.bmi_modal__container__scrollable__body}>
            <p className={styles.bmi_modal__container__scrollable__body__text}>
              <Trans components={{ strong: <strong />, br: <br /> }}>{t('MODAL_PARAGRAPH')}</Trans>
            </p>

            <div className={styles.bmi_modal__container__scrollable__body__example}>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <Text className={styles.bmi_modal__container__scrollable__body__example__row__text}>
                  <Trans>{t('MEDICAL_CONSULTATION')}</Trans>
                </Text>
                <Text
                  className={styles.bmi_modal__container__scrollable__body__example__row__price}
                >
                  <Trans>{t('MODAL_MEDICAL_CONSULTATION_COST')}</Trans>
                </Text>
              </div>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <Text
                  className={styles.bmi_modal__container__scrollable__body__example__row__text_blue}
                >
                  <Trans>{t('MODAL_FONASA')}</Trans>
                </Text>
                <Text
                  className={styles.bmi_modal__container__scrollable__body__example__row__discount}
                >
                  <Trans>{t('MODAL_FONASA_DISCOUNT')}</Trans>
                </Text>
              </div>
              <div
                className={styles.bmi_modal__container__scrollable__body__example__divider}
              ></div>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <Text className={styles.bmi_modal__container__scrollable__body__example__row__text}>
                  <Trans>{t('TOTAL_WITHOUT_INSURANCE')}</Trans>
                </Text>
                <Text
                  className={
                    styles.bmi_modal__container__scrollable__body__example__row__price__gray
                  }
                >
                  <Trans>{t('MODAL_TOTAL_WITHOUT_INSURANCE_PRICE')}</Trans>
                </Text>
              </div>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <Text
                  className={styles.bmi_modal__container__scrollable__body__example__row__text_blue}
                >
                  <Trans
                    values={{
                      planName: mostExpensivePlan?.name?.toUpperCase() ?? '',
                      refundPercentage,
                    }}
                  >
                    {t('MODAL_CSI')}
                  </Trans>
                </Text>
                <Text
                  className={styles.bmi_modal__container__scrollable__body__example__row__discount}
                >
                  -{currencyFormat(refoundAmount, 'CLP')}
                </Text>
              </div>
              <div
                className={styles.bmi_modal__container__scrollable__body__example__divider}
              ></div>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <div style={{ display: 'flex', gap: 4, marginTop: 5 }}>
                  <Image
                    src="/images/landing/features/pay_icon.svg"
                    width={24}
                    height={24}
                    alt=""
                  />
                  <Text
                    className={styles.bmi_modal__container__scrollable__body__example__row__text}
                  >
                    <Trans>{t('PAY')}</Trans>
                  </Text>
                </div>
                <Text
                  className={
                    styles.bmi_modal__container__scrollable__body__example__row__price__bold
                  }
                >
                  {currencyFormat(TOTAL_WITHOUT_INSURANCE - refoundAmount, 'CLP')}
                </Text>
              </div>
              <div className={styles.bmi_modal__container__scrollable__body__example__row}>
                <div style={{ display: 'flex', gap: 4 }}>
                  <Image
                    src="/images/landing/features/save_icon.svg"
                    width={24}
                    height={24}
                    alt=""
                  />
                  <Text
                    className={styles.bmi_modal__container__scrollable__body__example__row__text}
                  >
                    <Trans>{t('SAVED')}</Trans>
                  </Text>
                </div>
                <Text
                  className={
                    styles.bmi_modal__container__scrollable__body__example__row__price__green
                  }
                >
                  {currencyFormat(SAVED_WITH_FONASA + refoundAmount, 'CLP')}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bmi_modal__container__footer}>
          <Button size="l" className={styles.button} onClick={onClose}>
            {t('MODAL_BUTTON')}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default BmiModal;
