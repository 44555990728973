import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import classNames from 'classnames';
import Image from 'next/image';

import styles from './AdvantagesTab.module.scss';

const AdvantagesTab = ({ isActive }: { isActive: boolean }) => {
  const { t } = useTranslation('templates', {
    keyPrefix: 'LANDING_PLANS_TEMPLATES.ADVANTAGES',
  });

  const advantagesClassnames = classNames(styles.Advantages, {
    [styles.Advantages__notActive]: !isActive,
  });
  return (
    <div className={advantagesClassnames}>
      <div className={styles.Advantages__card}>
        <Image src="/images/icon/heart.png" height={80} width={80} alt="" />
        <Text className={styles.Advantages__card__title}>
          <Trans>{t('CARD_1.TITLE')}</Trans>
        </Text>
        <Text className={styles.Advantages__card__paragraph}>
          <Trans>{t('CARD_1.PARAGRAPH')}</Trans>
        </Text>
      </div>
      <div className={styles.Advantages__card}>
        <Image src="/images/icon/sign.svg" height={80} width={80} alt="" />
        <Text className={styles.Advantages__card__title}>
          <Trans>{t('CARD_2.TITLE')}</Trans>
        </Text>
        <Text className={styles.Advantages__card__paragraph}>
          <Trans>{t('CARD_2.PARAGRAPH')}</Trans>
        </Text>
      </div>
      <div className={styles.Advantages__card}>
        <Image src="/images/icon/hospital.svg" height={80} width={80} alt="" />
        <Text className={styles.Advantages__card__title}>
          <Trans>{t('CARD_3.TITLE')}</Trans>
        </Text>
        <Text className={styles.Advantages__card__paragraph}>
          <Trans>{t('CARD_3.PARAGRAPH')}</Trans>
        </Text>
      </div>
    </div>
  );
};

export default AdvantagesTab;
