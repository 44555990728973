import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import useDevice from '@src/hooks/screen/useDevice';
import Image from 'next/image';

import styles from './HeroLanding.module.scss';

type PositionPropsT = {
  positionX: number;
  positionY: number;
};

export type ChipHeroPropsT = {
  text: string;
  image: string;
  position: {
    desktop: PositionPropsT;
    mobile: PositionPropsT;
  };
};

const ChipHero: React.FC<ChipHeroPropsT> = ({ text, image, position }) => {
  const { isMobile } = useDevice();
  const { desktop, mobile } = position;
  const [posCoord, setPosCoord] = useState({});

  useEffect(() => {
    setPosCoord({
      left: `${isMobile ? mobile.positionX : desktop.positionX}px`,
      top: `${isMobile ? mobile.positionY : desktop.positionY}px`,
    });
  }, [isMobile]);

  if (!posCoord || !image) return null;

  return (
    <div className={styles.chip_hero} style={posCoord}>
      <Image src={image} height={50} width={50} alt="" className={styles.chip_hero_image} />
      {!isMobile && (
        <Text tag="p" className={styles.chip_hero_text}>
          <Trans i18nKey={text} components={{ span: <span /> }} />
        </Text>
      )}
    </div>
  );
};

export default ChipHero;
