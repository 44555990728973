import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import { Button } from '@bice_vida/ds-components-button';
import { Modal } from '@BICE_Vida/tsp-components';
import Image from 'next/image';

import styles from './QuantityMoreInformationModal.module.scss';

export type QuantityMoreInformationModalPropT = {
  onClose: () => void;
  isOpen: boolean;
};

const QuantityMoreInformationModal = ({ onClose, isOpen }: QuantityMoreInformationModalPropT) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} classNameModal={styles.modal_container}>
      <div className={`w-full d-flex flex-column align-items-center ${styles.container}`}>
        <div className="mb-4">
          <Image src="/svg/icon/companions.svg" height={80} width={80} alt="" />
        </div>
        <Text fontWeight={700} className={`mb-4 ${styles.first_title}`}>
          <Trans>DEPENDANT_QUOTE_HELP_MODAL_TITLE_1</Trans>
        </Text>
        <Text className="mb-3" fontTypo="body-small">
          <Trans components={{ span: <span /> }}>DEPENDANT_QUOTE_HELP_MODAL_DESCRIPTION_1</Trans>
        </Text>
        <ul>
          {[2, 3, 4, 5].map((item) => (
            <li
              key={`${item}_modal_description`}
              className={styles.Li}
              dangerouslySetInnerHTML={{
                __html: t(`DEPENDANT_QUOTE_HELP_MODAL_DESCRIPTION_${item}`),
              }}
            ></li>
          ))}
        </ul>
        <Button variant="primary" className={styles.action} onClick={onClose} size="l">
          {t('DEPENDANT_QUOTE_HELP_MODAL_ACTION')}
        </Button>
      </div>
    </Modal>
  );
};

export default QuantityMoreInformationModal;
